import axios from "../api-v2";

export const editEventData = (id) => {
  return axios.get(`/events/${id}/`);
};

export const fetchEventBanners = ({
  page,
  perPage,
  customParameters,
  country,
}) => {
  const response = axios.get(
    `/events?page=${page}&limit=${perPage}${customParameters || ""}${
      country ? `&country=${country}` : ""
    }`
  );
  return response;
};

export const fetchAllEvents = ({
  page,
  perPage,
  customParameters,
  country,
}) => {
  return axios.get(
    `/events?page=${page}&limit=${perPage}${customParameters || ""}${
      country ? `&country=${country}` : ""
    }`
  );
};

export const fetchAllIngressoEvents = async ({
  page = 1,
  perPage,
  customParameters,
  country,
  latitude,
  longitude,
}) => {
  const queryString = new URLSearchParams({
    pageNumber: page - 1,
    pageLength: perPage,
    latitude,
    longitude,
    country,
  })?.toString();

  const response = await axios.get(
    `/ingresso/events?${queryString}${customParameters || ""}`
  );
  // Transform response
  const { meta } = response?.data || {};
  const newResponse = {
    ...response,
    data: {
      ...response.data,
      data: {
        events: response.data?.data?.map((event) => ({ ...event, isIG: true })),
        totalCount: meta?.totalUnpagedResults,
        totalPages: meta?.pagesRemaining + 1,
        currentPage: meta?.pageNumber + 1,
        pageSize: meta?.pageLength,
      },
    },
  };
  //
  return newResponse;
};

export const fetchAllCategories = ({ page = 1, perPage, customParameters }) => {
  return axios.get(
    `/admin/event-category/get?page=${page}${
      perPage ? `&limit=${perPage}` : ""
    }${customParameters || ""}`
  );
};

export const fetchEventsByCategory = ({
  page = 1,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events?page=${page}${perPage ? `&limit=${perPage}` : ""}${
      customParameters || ""
    }`
  );
};

export const fetchEventById = (eventId) => {
  return axios.get(`/events/${eventId}`);
};

export const fetchEventBySerial = (eventSerial) => {
  return axios.get(`/events/event-serial/${eventSerial}`);
};

export const fetchIngressoEventById = (eventId) => {
  return axios.get(`/ingresso/events/${eventId}?reqExtraInfo=true`);
};

export const fetchEventTicketTypes = (eventId) => {
  return axios.get(`/events/${eventId}/ticket-types`);
};

export const fetchPaginatedEventTicketTypes = ({
  eventId,
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/ticket-types/list/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const fetchEventsOverallAnalysis = () => {
  return axios.get(`/events/overall-events-analysis`);
};

export const fetchUserEventsFinancialAnalysis = () => {
  return axios.get(`/events/user-events-financial-analysis`);
};

export const fetchEventTransactions = ({
  eventId,
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/reports/transactions/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const checkPromo = (eventId, { promoCode }) => {
  return axios.get(`/events/${eventId}/discount/${promoCode}/validate`);
};

export const addEventToSavedEvents = (eventId, isSaved) => {
  if (isSaved) {
    return axios.delete(`events/user/events/unsave/${eventId}`);
  }

  return axios.post(`/events/user/events/save`, {
    eventId,
  });
};

export const createEventTicket = (payload) => {
  return axios.post(`/events/${payload.eventId}/ticket-type`, payload);
};

export const updateEventTicket = (payload) => {
  return axios.patch(
    `/events/${payload.eventId}/ticket-type/${payload.id}`,
    payload
  );
};

export const deleteEventTicket = (payload) => {
  return axios.delete(`/events/${payload.eventId}/ticket-type/${payload.id}`);
};

export const updateEventSettingTicket = (payload) => {
  return axios.patch(`/events/${payload.eventId}/ticket-settings`, payload);
};

export const createMetaEventData = (payload) => {
  return axios.patch(`/events/meta/${payload.eventId}`, payload);
};

export const createEventData = (payload) => {
  return axios.post(`/events`, payload);
};

export const publishEventData = (payload) => {
  return axios.post(`/events/publish/${payload.eventId}`, payload);
};

export const eventsByUserListPaginated = ({
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/user/events/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const publishEvent = ({ eventId }) => {
  return axios.post(`/events/publish/${eventId}`, {});
};

export const unpublishEvent = ({ eventId }) => {
  return axios.post(`/events/unpublish/${eventId}`, {});
};

export const updateEventData = (eventId, data) => {
  return axios.patch(`/events/${eventId}`, data);
};

export const updateEventMetaData = (eventId, data) => {
  return axios.patch(`/events/meta/${eventId}`, data);
};

export const fetchEventCommission = (eventId) => {
  return axios.get(`/events/commission/${eventId}`);
};

export const fetchIGEventPerformance = (eventId) => {
  return axios.get(`/ingresso/events/${eventId}/performance`);
};

export const reserveIngressoTicket = (data) => {
  return axios.post(`/ingresso/reserve-ticket`, data);
};

export const eventPurchasedTicketListPaginated = ({
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/user/tickets/purchased/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const savedEventsByUserListPaginated = ({
  page = 1,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/user/events/saved/?page=${page}${
      perPage ? `&limit=${perPage}` : ""
    }${customParameters || ""}`
  );
};

export const fetchUserEventById = ({ eventId, userId }) => {
  return axios.get(`/events/user/${userId}/${eventId}`);
};

export const createAttendee = (eventId, data) => {
  return axios.post(`/events/${eventId}/attendee`, data);
};

export const attendeeListPaginated = ({
  eventId,
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/attendees/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const updateAttendee = (id, eventId, data) => {
  return axios.patch(`/events/${eventId}/attendee/${id}`, data);
};

export const deleteAttendee = (id, eventId) => {
  return axios.delete(`/events/${eventId}/attendee/${id}`);
};

export const createGuest = (eventId, data) => {
  return axios.post(`/events/${eventId}/guest`, data);
};

export const updateGuest = (id, eventId, data) => {
  return axios.patch(`/events/${eventId}/guest/${id}`, data);
};

export const guestListPaginated = ({
  eventId,
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/guests/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const deleteGuest = (id, eventId) => {
  return axios.delete(`/events/${eventId}/guest/${id}`);
};

export const createQuestion = (eventId, data) => {
  return axios.post(`/events/${eventId}/booking-question`, data);
};

export const questionListPaginated = ({
  eventId,
  page = 1,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/booking-question/?page=${page}${
      perPage ? `&limit=${perPage}` : ""
    }${customParameters || ""}`
  );
};

export const bookingInformationAnswersListPaginated = ({
  eventId,
  page = 1,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/booking/answers/?page=${page}${
      perPage ? `&limit=${perPage}` : ""
    }${customParameters || ""}`
  );
};

export const questionAnswersListPaginated = ({
  eventId,
  page = 1,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/booking/answers/?page=${page}${
      perPage ? `&limit=${perPage}` : ""
    }${customParameters || ""}`
  );
};

export const updateQuestion = (id, eventId, data) => {
  return axios.patch(`/events/${eventId}/booking-question/${id}`, data);
};

export const deleteQuestion = (id, eventId) => {
  return axios.delete(`/events/${eventId}/booking-question/${id}`);
};

export const createDiscount = (eventId, data) => {
  return axios.post(`/events/${eventId}/discount`, data);
};

export const updateDiscount = (id, eventId, data) => {
  return axios.patch(`/events/${eventId}/discount/${id}`, data);
};

export const discountListPaginated = ({
  eventId,
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/discount/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const deleteDiscount = (id, eventId) => {
  return axios.delete(`/events/${eventId}/discount/${id}`);
};

export const eventReminder = ({ eventId }) => {
  return axios.get(`/events/${eventId}/reminder`);
};

export const updateReminder = (eventId, data) => {
  return axios.post(`/events/${eventId}/reminder`, data);
};

export const eventOrdersListPaginated = ({
  eventId,
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/orders/history/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const eventBookingsListPaginated = ({
  eventId,
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/booking/history/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const getEventOrderById = (orderId) => {
  return axios.get(`/events/user/orders/${orderId}`);
};

export const getIngressoEventOrderById = (orderId) => {
  return axios.get(
    `/ingresso/orders/?page=1&pageSize=100&reference=${orderId}`
  );
};

export const eventTicketListPaginated = ({
  eventId,
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/${eventId}/tickets/list/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const checkInAttendee = (eventId, data) => {
  return axios.patch(`/events/${eventId}/tickets/check-in`, data);
};

export const userPurchasedTicketListPaginated = ({
  page,
  perPage,
  customParameters,
}) => {
  return axios.get(
    `/events/user/orders/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const shareTicket = (ticketId, data) => {
  return axios.post(`/events/user/tickets/share/${ticketId}`, data);
};

export const preBookTicket = (eventId, data) => {
  return axios.post(`/events/${eventId}/booking/pre-book`, data);
};

export const checkOutBooking = (eventId, data) => {
  return axios.post(`/events/${eventId}/booking/order`, data);
};

export const checkOutIngressoBooking = (data) => {
  return axios.post(`/ingresso/purchase-ticket`, data);
};

export const deleteUserEvent = (eventId) => {
  return axios.delete(`/events/${eventId}`);
};

export const getEventTicketSalesAnalysis = (eventId) => {
  return axios.get(`/events/${eventId}/reports/ticket-sales-analysis`);
};

export const getEventMonthlySales = (eventId) => {
  return axios.get(`/events/${eventId}/reports/monthly-sales`);
};

export const getEventTotalTicketsSales = (eventId) => {
  return axios.get(`/events/${eventId}/reports/total-sales`);
};

export const addContactListEvent = (data) => {
  return axios.post("/events/contacts", data);
};

export const editEventContactList = (contactId, payload) => {
  return axios.patch(`/events/contacts/${contactId}`, payload);
};

export const getEventContactList = ({ page, perPage, customParameters }) => {
  return axios.get(
    `/events/contacts/list/?page=${page}&limit=${perPage}${
      customParameters || ""
    }`
  );
};

export const addPromotersEvent = (data) => {
  return axios.post("/events/organizers/add", data);
};

export const editPromoter = (promoterId, payload) => {
  return axios.put(`/events/organizers/update/${promoterId}`, payload);
};

export const getEventsPromoterList = () => {
  return axios.get("/events/organizers/list?role=Promoter");
};

export const deleteEventContactList = (contactId) => {
  return axios.delete(`/events/contacts/${contactId}`);
};

export const deleteEventPromoter = (promoterId) => {
  return axios.delete(`/events/organizers/delete/${promoterId}`);
};
