import { findCountryByISO2 } from "../../../lib/country";
import TextInput from "../forms/TextInput";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import PlacesAutocomplete, {
  getLatLng,
  geocodeByPlaceId,
} from "react-places-autocomplete";
import { cn } from "utilities/cn";

const handleGooglePlacesPress = (results) => {
  const map = {
    street_number: "streetNumber",
    route: "streetName",
    sublocality_level_1: "city1",
    locality: "city",
    administrative_area_level_1: "state",
    administrative_area_level_2: "lga",
    postal_code: "postalCode",
    country: "country",
  };
  const brokenDownAddress = {};
  results.forEach((component) => {
    brokenDownAddress[map[component.types[0]]] = component.long_name;
  });
  return brokenDownAddress;
};

export default function PlaceSuggestionWrapper(props) {
  async function handleSelect(address, placeId) {
    try {
      const place = await geocodeByPlaceId(placeId);

      const actualPlace = place[0];
      const latlong = await getLatLng(actualPlace);
      const country = actualPlace.address_components.find(
        (compo) => compo.types[0] === "country"
      );
      const addyLength = actualPlace.address_components.length;
      const countryObject = findCountryByISO2(country.short_name);

      const approximateAddressComponents = handleGooglePlacesPress(
        actualPlace.address_components
      );

      const payload = {
        ...approximateAddressComponents,
        latitude: latlong.lat,
        longitude: latlong.lng,
        region: address,
        countryISO3: countryObject.iso3,
        countryLanguage: countryObject.language,
        countryCurrency: countryObject.currency,
        country: countryObject.name,
        placeId: actualPlace.place_id,
        street: actualPlace.formatted_address,
        pincode: actualPlace.address_components[addyLength - 1].long_name,
      };
      props.onSelect(payload);
    } catch (e) {
      // TODO: log error to error logger
    }
  }

  function renderSuggestions(suggestions, props) {
    return suggestions.length ? (
      <div
        className="search-dropdown position-absolute bg-white rounded-lg list-unstyled w-100"
        style={{ border: "solid 1px #d2cfcf", zIndex: "3", top: "43px" }}
      >
        {suggestions.map((suggestion) => (
          <div
            {...props(suggestion)}
            key={suggestion.placeId}
            className="d-flex align-items-center w-100 p-2 border-bottom cursor-pointer"
          >
            <FontAwesomeIcon
              className="text-sm text-dark"
              icon={faMapMarkerAlt}
            />
            <span className="text-dark ml-2 text-base tw-text-black">
              {suggestion.description}
            </span>
          </div>
        ))}
        {/* <div className="text-right p-2">
          <img
            src={
              "https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png"
            }
          />
        </div> */}
      </div>
    ) : null;
  }

  return (
    <PlacesAutocomplete
      value={props.value}
      onChange={props.onChange}
      onSelect={handleSelect}
      googleLogo
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div
          className={cn(
            "relative d-flex flex-row align-items-center justify-content-center position-relative text-wrap tw-px-3",
            props.className
          )}
        >
          {props.showIcon && (
            <div className="tw-absolute tw-left-0 tw-flex tw-items-center tw-justify-center tw-h-full tw-px-4 tw-py-1 focus:tw-text-black">
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm"
                  style={{ float: "right" }}
                />
              ) : (
                <FontAwesomeIcon className="text-md" icon={faMapMarkerAlt} />
              )}
            </div>
          )}
          <input
            {...getInputProps({
              placeholder: props.inputPlaceholder || "Search by Place or City",
              className: props.inputClassName,
            })}
            className={cn(
              "tw-p-3 tw-w-full tw-border tw-rounded-md focus:tw-text-black focus:tw-border focus-visible:tw-border focus-visible:tw-outline-none outline:tw-border focus-visible:tw-border-secondary-700 tw-input",
              props.showIcon && "!tw-pl-10",
              props.inputClassName
            )}
            ref={props.inputRef}
          />
          {renderSuggestions(suggestions, getSuggestionItemProps)}
        </div>
      )}
    </PlacesAutocomplete>
  );
}

PlaceSuggestionWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  inputClassName: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  showIcon: PropTypes.bool,
};
