import UnAuthencicatedDashboard from "components/layout/dashboard/unautheticatedDashboardLayout";
import { Button } from "components/shared/Button";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { requestVerificationEmail } from "services/network-v2/auth";

function DashboardNotVerified(props) {
  const [countdown, setCountdown] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state?.account?.data) || {};
  const { email } = user;

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsButtonDisabled(false);
    }
  }, [countdown]);

  const requestEmail = async () => {
    setLoading(true);
    try {
      const response = await requestVerificationEmail({
        email,
      });
      toast.success(`link has been sent to ${email}`);
      return response;
    } catch (err) {
      toast.error("Unable to send the verification email. Please try again.");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleResendEmail = () => {
    setCountdown(20);
    setIsButtonDisabled(true);
    requestEmail();
  };
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <UnAuthencicatedDashboard>
      <div className="tw-mx-auto tw-flex tw-items-center tw-flex-col tw-gap-0">
        <img
          className={"tw-w-[200px]  tw-mb-5 tw-h-[200px]"}
          alt={""}
          src={"/images/auth/otp_phone.svg"}
        />
        <h1
          className={" tw-font-[500] tw-text-xl tw-text-center md:tw-text-3xl"}
        >
          Check your email
        </h1>
        <p className={"text-center  tw-mt-3 tw-text-[#667085] tw-text-base "}>
          Your account is pending activation, please check your email for the
          activation email.
        </p>

        <p className="text-center  tw-mt-1 tw-text-[#667085] tw-text-base">
          Didn’t receive the email?{" "}
          <span className={"tw-text-primary-600"}>{formatTime(countdown)}</span>
        </p>
        <Button
          className={"tw-bg-primary-700 tw-py-0 tw-w-full tw-text-white"}
          disabled={isButtonDisabled}
          onClick={handleResendEmail}
          loading={loading}
        >
          Resend Email
        </Button>

        <Link to="/auth/login">
          <span
            className={
              "tw-flex tw-mt-4 tw-cursor-pointer tw-items-center tw-gap-2 tw-text-gray-500"
            }
          >
            {" "}
            <FaArrowLeft /> Back to log in
          </span>
        </Link>
      </div>
    </UnAuthencicatedDashboard>
  );
}

export default DashboardNotVerified;
