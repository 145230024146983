import FuelBuyerDashboardPageTitle from "../../../components/PageTitle";
import { EditIcon, EyeIcon, TrashIcon } from "assets/icons";
import EmptyTable from "components/pages/authenticated-new/pages/Events/shared/EmptyTable";
import BadgeComponent from "components/shared/Badge";
import TableSkeleton from "components/shared/TableSkeleton";
import DefaultDeleteModal from "components/shared/modal/deleteModal";
import DefaultTable from "components/shared/tables/defaultTable";
import { FUEL_BUYER_ORDER_TABLEHEADER } from "constants/tableHeaderConstants";
import { TABLE_STYLE_ENUMS } from "enums/styleEnums";
import usePagination from "hooks/usePagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Fuel as FuelV2 } from "services/network-v2";
import { fetchDashboardCount } from "services/network/event";
import {
  fetchBuyerDashboardRequest,
  fetchCustomersOrderRequest,
  fetchFuelStationTransactionsRequest,
} from "state/slices/fuel";
import { cn } from "utilities/cn";

const FuelBuyerOrdersTablePage = () => {
  const [activeOrder, setActiveOrder] = useState({});
  const [requestData, setRequestData] = useState([]);
  const { user } = useSelector((state) => state.users.data);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const showDeleteModal = (order) => {
    setActiveOrder(order);
    setOpenDeleteModal(true);
  };

  const [search, setSearch] = React.useState("");

  const { data, isLoading } = usePagination({
    request: fetchCustomersOrderRequest,
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await FuelV2.fetchFuelCustomersOrders();
  //       setRequestData(response?.data);
  //     } catch (error) {
  //       console.error("Error fetching customer orders:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  const { data: txLists } = usePagination({
    request: fetchFuelStationTransactionsRequest,
    dataSelector: "",
  });

  return (
    <>
      <DefaultDeleteModal
        isActive={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
      />

      <div>
        <div className="tw-flex tw-justify-between">
          <FuelBuyerDashboardPageTitle title="Transaction List" />

          <div className="tw-flex tw-gap-2">
            <input
              className="!tw-h-full !tw-min-h-full !tw-w-72 tw-border !tw-rounded-md tw-input"
              placeholder="Search"
            />

            {/* <Link to="/dashboard/fuel-station/vehicles/create">
              <Button className="tw-bg-green-500 tw-text-white">
                NEW PURCHASE
              </Button>
            </Link> */}
          </div>
        </div>

        <div className="tw-mt-6">
          <div className="tw-py-4 tw-text-base">
            {txLists?.length !== 0 ? (
              <DefaultTable headerData={FUEL_BUYER_ORDER_TABLEHEADER}>
                {isLoading ? (
                  <TableSkeleton rows={10} columns={5} />
                ) : (
                  txLists?.map((item, index) => {
                    return (
                      <tbody
                        className={TABLE_STYLE_ENUMS.TABLE_BODY}
                        key={item.id}
                      >
                        <tr className="tw-border-b">
                          <td className="tw-px-3 tw-py-3 tw-w-48">
                            {index + 1}
                          </td>
                          <td className="tw-px-3 tw-py-3 tw-w-48">
                            {item?.amount}
                          </td>

                          <td className="tw-px-3 tw-py-3">{item?.service}</td>
                          <td className="tw-px-3 tw-py-3">
                            <BadgeComponent status={item?.status} />
                          </td>
                          <td className={"tw-px-3 tw-py-3"}>
                            {moment(item?.updatedAt).format("lll")}
                          </td>
                          <td className="tw-px-3 tw-py-3">
                            <div className="tw-flex tw-gap-4">
                              <Link
                                to={`/dashboard/fuel-station/orders/${item?.reference}`}
                              >
                                <EyeIcon className="tw-w-5 tw-h-5 tw-cursor-pointer hover:tw-text-secondary-500" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                )}
              </DefaultTable>
            ) : (
              <EmptyTable
                title="No Orders(s) Available"
                description=" Once any order(s) is created, they will appear here."
                className="tw-mb-6"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FuelBuyerOrdersTablePage;
