import axios from "axios";
import { IS_VENDOR_LOGGEDIN } from "constant";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_V2_BASE_URL,
  timeout: 30 * 1000,
});

Axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorMessage = error.message || error.response?.data?.message;
    const statusCode = error.response?.status;

    if (statusCode === 401 || statusCode === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("persist:root");
      localStorage.removeItem("storeUserRole");
      localStorage.removeItem("user");
      localStorage.removeItem(IS_VENDOR_LOGGEDIN);
      window.location.replace("/auth/login");
      return Promise.reject(error);
    }

    const suppressMessages = [
      "call cancelled",
      "call canceled",
      "call rejected",
      "rejected",
      "canceled",
      "cancelled",
    ];

    if (
      statusCode === 408 ||
      suppressMessages.some((msg) =>
        errorMessage?.toLowerCase().includes(msg.toLowerCase())
      )
    ) {
      return Promise.resolve();
    }

    return Promise.reject(error);
  }
);

export default Axios;
