import { LocationIcon } from "assets/icons";
import { findCurrencySymbolByCode } from "lib/country";
import {
  getEventCheapestTicket,
  getEventPath,
  getEventThumbnailUrl,
} from "lib/event";
import { thousandFormatter } from "lib/number";
import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const SearchResultEvent = ({ event, setShowSearchSuggestions }) => {
  const history = useHistory();

  const cheapestTicket = getEventCheapestTicket(event);

  const eventPath = getEventPath(event);

  const goToEvent = () => {
    history.push(eventPath);
    setShowSearchSuggestions(false);
  };

  return (
    <Link to={eventPath}>
      <div
        onClick={() => goToEvent()}
        className="tw-flex tw-full tw-gap-4 tw-group hover:tw-bg-gray-100 tw-cursor-pointer tw-p-2 tw-text-black"
      >
        <div className="tw-w-3/12">
          <img
            alt="event-image"
            className="tw-w-full tw-h-full tw-rounded-md"
            src={getEventThumbnailUrl(event)}
          />
        </div>
        <div className="tw-w-9/12">
          <span className="tw-font-medium tw-text-base tw-line-clamp-1">
            {event.title}
          </span>
          <div className="tw-flex tw-items-center tw-justify-between">
            <div className="tw-text-sm">
              From{" "}
              <span className="tw-uppercase">
                {findCurrencySymbolByCode(
                  !event.isIG ? event.currency : cheapestTicket.currency || ""
                )}
                {thousandFormatter(cheapestTicket?.price || 0)}
              </span>
            </div>
            {event.category && (
              <span className="tw-bg-gray-100 tw-p-1 tw-rounded-md tw-text-xs tw-px-4 group-hover:tw-bg-white">
                {event.category?.name}
              </span>
            )}
          </div>
          <div className="tw-flex tw-items-center tw-gap-2 tw-text-xs tw-mt-2">
            <LocationIcon />{" "}
            <span>
              {!event.isIG ? (
                <>
                  {event.venueDetails?.city}, {event.venueDetails?.state}
                </>
              ) : (
                event?.venueDetails?.venueName
              )}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SearchResultEvent;
