import { EventFallbackImage } from "assets/images";
import React from "react";
import { useImage } from "react-image";

export const ImageComponent = ({ src: srcList, ...props }) => {
  const { src, error } = useImage({
    srcList: [srcList, EventFallbackImage],
    useSuspense: false,
    errorImage: EventFallbackImage,
  });

  if (error) {
    return <img src={EventFallbackImage} alt={props.alt || ""} {...props} />;
  }

  return (
    <img src={src || EventFallbackImage} alt={props.alt || ""} {...props} />
  );
};
