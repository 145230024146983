import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { current } from "immer";
import { findIso2ByISO3 } from "lib/country";
import { Event } from "services/network";
import { Event as EventV2 } from "services/network-v2";

const allEventsInitialState = {
  events: [],
};

const popularEventsInitialState = {
  eventsData: [],
};

const eventBannersInitialState = {
  banners: [],
};

const initialState = {
  userSavedEvents: [],
  categoriesIsLoading: false,
  newCategoriesIsLoading: false,
  categoriesFetched: false,
  categoriesData: [],
  newCategoriesData: [],
  allEventsIsLoading: false,
  allEventsFetched: false,
  allEventsData: allEventsInitialState,
  popularEventsIsLoading: false,
  popularEventsFetched: false,
  popularEventsData: popularEventsInitialState,
  eventBannersIsLoading: false,
  eventBannersFetched: false,
  eventBannersData: eventBannersInitialState,
  relatedEventsIsLoading: false,
  relatedEventsFetched: false,
  relatedEventsData: [],
  categoryEventsIsLoading: false,
  categoryEventsFetched: false,
  categoryEventsData: {},
  eventDashboardData: {},
};

export const addEventPromoterRequest = createAsyncThunk(
  "/events/promoters",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.addPromotersEvent(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventsPromoters = createAsyncThunk(
  "",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.getEventsPromoterList();
      return response;
    } catch (err) {
      if (!err.response) throw err;
    }
  }
);

export const updateEventPromoterRequest = createAsyncThunk(
  "events/promoter",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.editPromoter(payload.id, payload.data);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEventPromoterRequest = createAsyncThunk(
  "events/promoter",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.deleteEventPromoter(payload);
      return response;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteContactListRequest = createAsyncThunk(
  "events/contacts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.deleteEventContactList(payload);
      return response;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEventContactListRequest = createAsyncThunk(
  "events/contacts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.editEventContactList(
        payload.id,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventsContactList = createAsyncThunk(
  "events/contacts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.getEventContactList(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventsByUserRequest = createAsyncThunk(
  "events/fetchUserEvents",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.eventsByUserListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addContactListRequest = createAsyncThunk(
  "/events/contacts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.addContactListEvent(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchUserEventByIdRequest = createAsyncThunk(
  "events/fetchUserEventById",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.fetchUserEventById(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchSavedEventsByUserRequest = createAsyncThunk(
  "events/saved",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.savedEventsByUserListPaginated(payload);

      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addEventToSavedEventsRequest = createAsyncThunk(
  "events/saveEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.addEventToSavedEvents(
        payload.eventId,
        payload.data
      );
      return { ...response.data, eventId: payload.eventId };
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const publishEventRequest = createAsyncThunk(
  "events/publishEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.publishEvent(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const unpublishEventRequest = createAsyncThunk(
  "events/unpublishEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.unpublishEvent(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventBannersRequest = createAsyncThunk(
  "events/fetchEventBanners",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await EventV2.fetchEventBanners({
        ...payload,
        country: getState().location.country,
      });
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllEventCategoriesV2Request = createAsyncThunk(
  "events/fetchAllNewCategories",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await EventV2.fetchAllCategories({
        ...payload,
        atLocation: getState().location.region,
        lat: getState().location.latitude,
        long: getState().location.longitude,
      });
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const preBookTicketRequest = createAsyncThunk(
  "events/preBookTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.preBookTicket(
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkOutBookingRequest = createAsyncThunk(
  "events/checkOutBooking",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.checkOutBooking(
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkOutIngressoBookingRequest = createAsyncThunk(
  "events/checkOutIngressoBooking",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.checkOutIngressoBooking(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllEventCategoriesRequest = createAsyncThunk(
  "events/fetchAllCategories",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await Event.fetchAllCategories({
        ...payload,
        atLocation: getState().location.region,
        lat: getState().location.latitude,
        long: getState().location.longitude,
      });
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllEventsByNameRequest = createAsyncThunk(
  "events/fetchAllByName",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.fetchAllEventsByName({
        eventName: payload.eventName,
        next: 0,
      });
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllEventsRequest = createAsyncThunk(
  "events/fetchAll",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await EventV2.fetchAllEvents({
        ...payload,
        country: getState().location.country,
        lat: getState().location.latitude,
        long: getState().location.longitude,
      });

      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchAllIngressoEventsRequest = createAsyncThunk(
  "events/fetchAllIngressoEvents",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await EventV2.fetchAllIngressoEvents({
        ...payload,
        latitude: getState().location.latitude,
        longitude: getState().location.longitude,
        country: findIso2ByISO3(getState().location.countryISO3)?.toLowerCase(),
      });
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPopularEventsRequest = createAsyncThunk(
  "events/fetchPopularEvents",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await Event.fetchAllEvents({
        atLocation: getState().location.region,
        lat: getState().location.latitude,
        long: getState().location.longitude,
        perPage: 5,
      });
      return response.data.detail;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchRelatedEventsByCountryRequest = createAsyncThunk(
  "events/fetchRelatedEvents",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await Event.fetchEventByCountry(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventsByCategoryRequest = createAsyncThunk(
  "events/fetchByCategory",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await EventV2.fetchEventsByCategory({
        ...payload,
        customParameters: `&category=${payload.customParameters?.category}`,
      });
      return {
        category: payload.customParameters?.category,
        data: response.data.data,
      };
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchFilteredEventsRequest = createAsyncThunk(
  "events/fetchFiltered",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await Event.fetchFilteredEvents({
        ...payload,
        atLocation: getState().location.region,
        lat: getState().location.latitude,
        long: getState().location.longitude,
      });
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventByIdRequest = createAsyncThunk(
  "events/fetchById",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.fetchEventById(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventBySerialRequest = createAsyncThunk(
  "events/fetchBySerial",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.fetchEventBySerial(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchIngressoEventByIdRequest = createAsyncThunk(
  "events/fetchIngressoEventById",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.fetchIngressoEventById(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventTicketTypesRequest = createAsyncThunk(
  "events/fetchTicketTypes",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await EventV2.fetchEventTicketTypes(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchPaginatedEventTicketTypesRequest = createAsyncThunk(
  "events/fetchPaginatedTicketTypes",
  async (payload, { rejectWithValue, getState }) => {
    try {
      const response = await EventV2.fetchPaginatedEventTicketTypes(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventsOverallAnalysisRequest = createAsyncThunk(
  "events/fetchEventsOverallAnalysis",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.fetchEventsOverallAnalysis(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchUserEventsFinancialAnalysisRequest = createAsyncThunk(
  "events/fetchUserEventsFinancialAnalysis",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.fetchUserEventsFinancialAnalysis(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventTransactionsRequest = createAsyncThunk(
  "events/fetchEventTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.fetchEventTransactions(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventCommissionRequest = createAsyncThunk(
  "events/fetchEventCommission",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.fetchEventCommission(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchIGEventPerformanceRequest = createAsyncThunk(
  "events/fetchIGEventPerformance",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.fetchIGEventPerformance(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const reserveIngressoTicketRequest = createAsyncThunk(
  "events/reserveIngressoTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.reserveIngressoTicket(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchT7PerformanceTicketsByIdRequest = createAsyncThunk(
  "events/fetchT7PerformanceTicketsById",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.fetchT7PerformanceTicketsById(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchT7PerformanceDeliveryMethodsByIdRequest = createAsyncThunk(
  "events/fetchT7PerformanceDeliveryMethodsById",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.fetchT7PerformanceDeliveryMethodsById(
        payload
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventNameSuggestionsRequest = createAsyncThunk(
  "events/fetchNameSuggestions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.fetchNameSuggestions(payload);

      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventPlaceSuggestionsRequest = createAsyncThunk(
  "events/fetchPlaceSuggestions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.fetchPlaceSuggestions(payload);
      return response.data.candidates;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchBookedEventsRequest = createAsyncThunk(
  "events/booked",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.fetchBookedEvents();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const reserveT7ETicketAndTransactionRequest = createAsyncThunk(
  "events/reserveT7ETicketAndTransaction",
  async (payload, { rejectWithValue }) => {
    const { reserveTicket, reserveTransaction } = payload;
    try {
      const reserveTicketRes = await Event.reserveT7ETicket(reserveTicket);
      const reserveTransactionRes = await Event.reserveT7ETransaction({
        payload: {
          ...reserveTransaction.payload,
          transactionUuid: reserveTicketRes.data.transactionUuid,
        },
      });
      return reserveTransactionRes.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addTicketToTrolleyRequest = createAsyncThunk(
  "events/reserveT7ETicketAndTransaction",
  async (payload, { rejectWithValue }) => {
    const { ticketBundle, ...rest } = payload;
    try {
      let token = "";
      let hasUnavailableOrder = false;
      const firstPayload = {
        ...rest,
        trolleyToken: "",
        noOfSeats: ticketBundle[0].noOfSeats,
        ticketId: ticketBundle[0].ticketId,
      };
      const firstResp = await Event.addTicketToTrolley(firstPayload);
      token = firstResp.data.trolleyToken;
      hasUnavailableOrder = firstResp.data.inputContainedUnavailableOrder;
      if (ticketBundle.length > 1) {
        const newTickets = ticketBundle.slice(1);
        for (let tickt of newTickets) {
          const response = await Event.addTicketToTrolley({
            ...rest,
            ...tickt,
            trolleyToken: token,
          });
          if (!hasUnavailableOrder) {
            hasUnavailableOrder = response.data.inputContainedUnavailableOrder;
          }
          token = response.data.trolleyToken;
        }
      }

      if (hasUnavailableOrder) {
        throw new Error("The selected ticket is no longer available");
      }

      return token;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const reserveIGNonSeatingTicket = createAsyncThunk(
  "events/reserveT7ETicketAndTransaction",
  async (payload, { rejectWithValue }) => {
    try {
      const reserveTicketRes = await Event.reserveIGNonSeatingTicket(
        payload.reserveIGPayload
      );
      const reserveTransactionRes = await Event.reserveIGSeatingTransaction({
        payload: {
          ...payload.reserveTransactionPayload,
          trolleyToken: reserveTicketRes.data.trolleyToken,
          transactionUuid:
            reserveTicketRes.data.trolleyContents.transactionUuid,
        },
      });
      return reserveTransactionRes.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const reserveIGSeatingTicketAndTransactionRequest = createAsyncThunk(
  "events/reserveIGSeatingTicketAndTransaction",
  async (payload, { rejectWithValue }) => {
    const { reserveSeat, reserveTransaction } = payload;
    try {
      const reserveSeatRes = await Event.reserveIGSeatingTicket(reserveSeat);
      const reserveTransactionRes = await Event.reserveIGSeatingTransaction({
        payload: {
          ...reserveTransaction.payload,
          transactionUuid: reserveSeatRes.data.trolleyContents.transactionUuid,
          timeofreserve: reserveSeatRes.data.timeofreserve,
        },
      });
      return reserveTransactionRes.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchMyEventsRequest = createAsyncThunk(
  "events/my",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.fetchMyEvents();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchDashboardCountRequest = createAsyncThunk(
  "events/dashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.fetchDashboardCount();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUserEventRequest = createAsyncThunk(
  "events/deleteUserEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.deleteUserEvent(payload.eventId);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteMyEventsRequest = createAsyncThunk(
  "events/deleteMyEvents",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.deleteMyEvents();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchEventsGalleryRequest = createAsyncThunk(
  "events/eventGallery",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getEventGalleries(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadGalleryRequest = createAsyncThunk(
  "events/uploadGallery",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.uploadGallery(payload.id, payload.data);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const eventDashboardRequest = createAsyncThunk(
  "events/eventDashboard",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.eventDashboard(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const eventSummaryRequest = createAsyncThunk(
  "events/eventSummary",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.eventSummary(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAttendeeRequest = createAsyncThunk(
  "events/createAttendee",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.createAttendee(
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const attendeeListPaginatedRequest = createAsyncThunk(
  "events/attendeeList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.attendeeListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAttendeeRequest = createAsyncThunk(
  "events/updateAttendee",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.updateAttendee(
        payload.id,
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAttendeeRequest = createAsyncThunk(
  "events/deleteAttendee",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.deleteAttendee(
        payload.id,
        payload.eventId
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const eventTicketListPaginatedRequest = createAsyncThunk(
  "events/eventTicketList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.eventTicketListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const shareTicketRequest = createAsyncThunk(
  "events/shareTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.shareTicket(
        payload.ticketId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const userPurchasedTicketListPaginatedRequest = createAsyncThunk(
  "events/userPurchasedTicketList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.userPurchasedTicketListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const eventPurchasedTicketListPaginatedRequest = createAsyncThunk(
  "events/purchasedTicketList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.eventPurchasedTicketListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const guestListRequest = createAsyncThunk(
  "events/guestList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.guestList(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const guestListPaginatedRequest = createAsyncThunk(
  "events/guestList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.guestListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createGuestRequest = createAsyncThunk(
  "events/createGuest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.createGuest(payload.eventId, payload.data);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGuestRequest = createAsyncThunk(
  "events/updateGuest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.updateGuest(
        payload.id,
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGuestRequest = createAsyncThunk(
  "events/deleteGuest",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.deleteGuest(payload.id, payload.eventId);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEventRequest = createAsyncThunk(
  "events/updateEventData",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.updateEventData(
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEventMetaRequest = createAsyncThunk(
  "events/updateEventMetaData",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.updateEventMetaData(
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEditEventRequest = createAsyncThunk(
  "events/editEventData",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.editEventData(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const togglePublishRequest = createAsyncThunk(
  "events/togglePublish",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.togglePublish(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDiscountRequest = createAsyncThunk(
  "events/getDiscount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getDiscount(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const discountListPaginatedRequest = createAsyncThunk(
  "events/discountList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.discountListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDiscountRequest = createAsyncThunk(
  "events/createDiscount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.createDiscount(
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateDiscountRequest = createAsyncThunk(
  "events/updateDiscount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.updateDiscount(
        payload.id,
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDiscountRequest = createAsyncThunk(
  "events/deleteDiscount",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.deleteDiscount(
        payload.id,
        payload.eventId
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const eventReminderRequest = createAsyncThunk(
  "events/eventReminder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.eventReminder(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReminderRequest = createAsyncThunk(
  "events/updateReminder",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.updateReminder(
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPayoutsRequest = createAsyncThunk(
  "events/getPayouts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getPayouts(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStepOutRequest = createAsyncThunk(
  "events/getStepOut",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getStepOut(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateStepOutRequest = createAsyncThunk(
  "events/updateStepOut",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.updateStepOut(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAnalysisRequest = createAsyncThunk(
  "events/getAnalysis",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getAnalysis(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAttendeePaginatedListsRequest = createAsyncThunk(
  "events/getAttendeeLists",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getAttendeePaginatedLists(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const checkInAttendeeRequest = createAsyncThunk(
  "events/checkInAttendee",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.checkInAttendee(
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addThankYouVideoRequest = createAsyncThunk(
  "events/addThankYouVideo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.addThankYouVideo(
        payload.eventId,
        payload.formData
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const watchThankYouVideoRequest = createAsyncThunk(
  "events/watchThankYouVideo",
  async (eventId, { rejectWithValue }) => {
    try {
      const response = await Event.watchThankYouVideo(eventId);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addAffiliateRequest = createAsyncThunk(
  "events/addAffiliate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.addAffiliate(
        payload.eventId,
        payload.formPayload
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventQuestionsRequest = createAsyncThunk(
  "events/getQuestion",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.questionListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventBookingInformationAnswersRequest = createAsyncThunk(
  "events/getBookingInformationAnswers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.bookingInformationAnswersListPaginated(
        payload
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const questionListPaginatedRequest = createAsyncThunk(
  "events/getQuestionList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.questionListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const questionAnswersListPaginatedRequest = createAsyncThunk(
  "events/getQuestionList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.questionAnswersListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateQuestionRequest = createAsyncThunk(
  "events/updateQuestion",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.updateQuestion(
        payload.id,
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createQuestionRequest = createAsyncThunk(
  "events/createQuestion",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.createQuestion(
        payload.eventId,
        payload.data
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteQuestionRequest = createAsyncThunk(
  "events/deleteQuestion",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.deleteQuestion(
        payload.id,
        payload.eventId
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventTicketSalesAnalysisRequest = createAsyncThunk(
  "events/getEventTicketSalesAnalysis",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.getEventTicketSalesAnalysis(
        payload.eventId
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventMonthlySalesRequest = createAsyncThunk(
  "events/getEventMonthlySales",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.getEventMonthlySales(payload.eventId);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventTotalTicketsSalesRequest = createAsyncThunk(
  "events/getEventTotalTicketsSales",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.getEventTotalTicketsSales(payload.eventId);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAnswersRequest = createAsyncThunk(
  "events/getAnswers",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getAnswers(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getStripeResponseRequest = createAsyncThunk(
  "events/getStripeResponse",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getStripeResponse(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCategoryChoiceListRequest = createAsyncThunk(
  "events/getCategoryChoiceList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getCategoryChoiceList();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createEventTicketRequest = createAsyncThunk(
  "events/createEventTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.createEventTicket(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEventTicketRequest = createAsyncThunk(
  "events/updateEventTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.updateEventTicket(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEventTicketRequest = createAsyncThunk(
  "events/deleteEventTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.deleteEventTicket(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateEventTicketSettingsRequest = createAsyncThunk(
  "events/UpdateEventTicketSettings",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.updateEventSettingTicket(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createEventDataRequest = createAsyncThunk(
  "events/createEventData",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.publishEventData(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createMetaEventDataRequest = createAsyncThunk(
  "events/createMetaEventData",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.createMetaEventData(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveEventMediaRequest = createAsyncThunk(
  "events/saveEventMedia",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.saveEventMedia(payload.files, payload.id);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendMailIVRequest = createAsyncThunk(
  "events/sendMail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.sendMail(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const addEventContactRequest = createAsyncThunk(
  "events/sendMail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.addeventContact(
        payload.formPayload,
        payload.id
      );
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventContactsRequest = createAsyncThunk(
  "events/getEventContacts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getEventContacts(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteEventContactRequest = createAsyncThunk(
  "events/deleteEventContacts",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.deleteEventContacts(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const saveOtherFilesRequest = createAsyncThunk(
  "events/saveOtherFiles",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.saveOtherFiles(payload.files, payload.id);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const createDraftEventRequest = createAsyncThunk(
  "events/createDraftEvent",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.createEventData(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTimeZoneRequest = createAsyncThunk(
  "events/getTimeZone",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getTimeZone(payload.lat, payload.long);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadResaleTicketRequest = createAsyncThunk(
  "events/uploadTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.uploadResaleTicket(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getResaleTicketRequest = createAsyncThunk(
  "events/getResaleTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getResaleTicket(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteResaleTicketRequest = createAsyncThunk(
  "events/deleteResaleTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.deleteResaleTicket(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getResaleRelationsRequest = createAsyncThunk(
  "events/getResaleRelations",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getResaleRelations(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);
export const getEventOrdersRequest = createAsyncThunk(
  "events/getEventOrders",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.eventOrdersListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventBookingsRequest = createAsyncThunk(
  "events/getEventBookings",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.eventBookingsListPaginated(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventOrderByIdRequest = createAsyncThunk(
  "events/getEventOrderById",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.getEventOrderById(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getIngressoEventOrderByIdRequest = createAsyncThunk(
  "events/getIngressoEventOrderById",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.getIngressoEventOrderById(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteOtherFileRequest = createAsyncThunk(
  "events/deleteOtherFile",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.deleteOtherFile(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const VerifyPinRequest = createAsyncThunk(
  "events/VerifyPin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.VerifyPin(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const contactAdminRequest = createAsyncThunk(
  "events/contactAdmin",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.contactAdmin(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBookingQuestionsRequest = createAsyncThunk(
  "events/getBookingQuestions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getBookingQuestions(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const CheckPromoRequest = createAsyncThunk(
  "events/checkPromo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await EventV2.checkPromo(payload.eventId, payload.data);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventTicketOverviewRequest = createAsyncThunk(
  "events/getEventTicketOverview",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getEventTicketOverview();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventTicketChartRequest = createAsyncThunk(
  "events/getEventTicketChart",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getEventTicketChart(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventTransactionsRequest = createAsyncThunk(
  "events/getEventTransactions",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getEventTransactions(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventTransactionsByIDRequest = createAsyncThunk(
  "events/getEventTransactionsByID",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getEventTransactionsByID(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const getEventTicketDetailRequest = createAsyncThunk(
  "events/getEventTicketDetail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Event.getEventTicketDetail(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

const eventsSlice = createSlice({
  name: "events",
  initialState: initialState,
  reducers: {
    updateAllEventsData: (state, action) => {
      state.allEventsData = action.payload.data;
    },
    resetAllEventsData: (state) => {
      state.allEventsData = allEventsInitialState;
    },
  },
  extraReducers: {
    [fetchEventBannersRequest.pending]: (state, action) => {
      state.categoriesIsLoading = true;
    },
    [fetchSavedEventsByUserRequest.fulfilled]: (state, action) => {
      state.userSavedEvents = action.payload.data.events || [];
    },
    [addEventToSavedEventsRequest.fulfilled]: (state, action) => {
      const oldUserSavedEvents = state.userSavedEvents
        ? [...current(state.userSavedEvents)]
        : [];
      const eventId = action.payload.eventId;
      const findEventIndex = oldUserSavedEvents.findIndex(
        (event) => event.id === eventId
      );
      if (findEventIndex > -1) {
        oldUserSavedEvents?.splice(findEventIndex, 1);
      } else {
        oldUserSavedEvents.push({ id: eventId });
      }
      state.userSavedEvents = oldUserSavedEvents;
    },
    [fetchEventBannersRequest.fulfilled]: (state, action) => {
      state.eventBannersIsLoading = false;
      state.eventBannersFetched = true;
      state.eventBannersData = action.payload.data;
    },
    [fetchAllEventCategoriesRequest.pending]: (state, action) => {
      state.categoriesIsLoading = true;
    },
    [fetchAllEventCategoriesRequest.fulfilled]: (state, action) => {
      state.categoriesIsLoading = false;
      state.categoriesFetched = true;
      state.categoriesData = action.payload;
    },
    [fetchAllEventCategoriesV2Request.pending]: (state, action) => {
      state.newCategoriesIsLoading = true;
    },
    [fetchAllEventCategoriesV2Request.fulfilled]: (state, action) => {
      state.newCategoriesIsLoading = false;
      state.newCategoriesData = action.payload.data.categories || [];
    },
    [fetchAllEventsRequest.pending]: (state) => {
      state.allEventsIsLoading = true;
    },
    [fetchAllEventsRequest.fulfilled]: (state, action) => {
      state.allEventsIsLoading = false;
      state.allEventsFetched = true;

      if (action.payload?.data?.currentPage === 1) {
        state.allEventsData = {
          ...action.payload.data,
          events: [
            ...(action.payload.data?.events ? action.payload.data?.events : []),
          ],
        };
      } else {
        state.allEventsData = {
          ...action.payload.data,
          events: [
            ...current(state.allEventsData.events),
            ...(action.payload.data?.events ? action.payload.data?.events : []),
          ],
        };
      }
    },
    [fetchEventsByCategoryRequest.pending]: (state) => {
      state.categoryEventsIsLoading = true;
    },
    [fetchEventsByCategoryRequest.fulfilled]: (state, action) => {
      const category = action.payload.category;
      state.categoryEventsIsLoading = false;
      state.categoryEventsFetched = true;
      if (
        action.payload.locationInfo?.atLocation &&
        state.categoryEventsData[category]?.locationInfo?.atLocation[0] !==
          action.payload.data.locationInfo?.atLocation[0]
      ) {
        state.categoryEventsData = {
          ...state.categoryEventsData,
          [category]: action.payload.data,
        };
      } else {
        // state.categoryEventsData = {
        //   ...state.categoryEventsData,
        //   [category]: state.categoryEventsData[category]
        //     ? {
        //         ...action.payload.data,
        //         events: [
        //           ...state.categoryEventsData[category].events,
        //           ...action.payload.data.events,
        //         ],
        //       }
        //     : action.payload.data,
        // };
        state.categoryEventsData = {
          ...state.categoryEventsData,
          [category]: action.payload.data,
        };
      }
    },
    [fetchPopularEventsRequest.pending]: (state) => {
      state.popularEventsIsLoading = true;
    },
    [fetchPopularEventsRequest.fulfilled]: (state, action) => {
      state.popularEventsIsLoading = false;
      state.popularEventsFetched = true;
      state.popularEventsData = action.payload;
    },
    [fetchRelatedEventsByCountryRequest.pending]: (state) => {
      state.relatedEventsIsLoading = true;
    },
    [fetchRelatedEventsByCountryRequest.fulfilled]: (state, action) => {
      state.relatedEventsIsLoading = false;
      state.relatedEventsFetched = true;
      state.relatedEventsData = action.payload;
    },
    [eventDashboardRequest.fulfilled]: (state, action) => {
      state.eventDashboardData = action.payload;
    },
  },
});

export const { updateAllEventsData, resetAllEventsData } = eventsSlice.actions;
export default eventsSlice.reducer;
