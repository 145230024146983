import { SELECT_CUSTOM_CONTROL_STYLES } from "../constants";
import {
  MONTHLY_OCCURRENCE,
  RECURRING_FREQUENCIES,
  RECURRING_FREQUENCY_VALUES,
  RECURRING_REAPEAT_DAYS,
  RECURRING_TYPE_VALUES,
  THIRTY_ONE_DAYS,
} from "./constants";
import { Icon } from "@iconify/react";
import DatePicker from "components/shared/forms/DatePicker";
import InputHelpTooltip from "components/shared/forms/InputHelpTooltip";
import ThemedSelect from "components/shared/forms/ThemedSelect";
import { Field, FieldArray } from "formik";
import React, { useEffect } from "react";
import { convertArrayToSelectOptions } from "utilities";
import { cn } from "utilities/cn";

const RecurringDates = ({ setFieldValue, values }) => {
  const findRecurringWeekDayIndex = (day) =>
    values.recurringRepeatDays?.findIndex((item) => item === day);

  const findRecurringDateIndex = (day) =>
    values.recurringDays?.findIndex((item) => item === day);

  useEffect(() => {
    const resolveDefaultRecurringType = () => {
      // If recurring frequency is not monthly, then recurring type should be null
      if (
        values.recurringFrequency &&
        values.recurringFrequency.value !== RECURRING_FREQUENCY_VALUES.Monthly
      )
        return setFieldValue("recurringType", null);

      // If recurring frequency is monthly and recurring type is null, then make recurring type default to week
      if (
        !values.recurringType &&
        values.recurringFrequency &&
        values.recurringFrequency?.value === RECURRING_FREQUENCY_VALUES.Monthly
      ) {
        return setFieldValue("recurringType", RECURRING_TYPE_VALUES.Week);
      }
    };

    resolveDefaultRecurringType();
  }, [setFieldValue, values.recurringFrequency, values.recurringType]);

  return (
    <div>
      <div className="tw-flex tw-gap-2">
        <input
          type="checkbox"
          id="vehicle1"
          name="vehicle1"
          value="Bike"
          onChange={() => setFieldValue("recurring", !values.recurring)}
          checked={values.recurring}
        />
        <label
          htmlFor="vehicle1"
          className="tw-mt-2 tw-flex tw-items-center tw-gap-2"
        >
          Recurring Event
          <InputHelpTooltip helpDescription="Select this option if your events will re-occur consistently for a particular period of time">
            <Icon icon="zondicons:exclamation-solid" />
          </InputHelpTooltip>
        </label>
      </div>

      {values.recurring && (
        <div className="tw-mt-6 tw-bg-gray-50 sm:tw-mx--3 md:tw--mx-6 tw-p-8">
          <div className="tw-flex tw-flex-col tw-gap-6 tw-w-full md:tw-w-1/2">
            <div className="tw-grid tw-grid-cols-2 tw-gap-6">
              <div>
                <Field
                  component={DatePicker}
                  placeholder="DD-MM-YYYY"
                  minDate={new Date()}
                  className="tw-input !tw-border-gray-300 !tw-w-full"
                  name="endDateOfRecurring"
                  id="endDateOfRecurring"
                  type="date"
                  label="End Date For Recurring"
                  labelClassName="tw-ml-4"
                  onChange={(date) => setFieldValue("endDateOfRecurring", date)}
                  required
                />
              </div>
              <div>
                <Field
                  component={ThemedSelect}
                  name="recurringFrequency"
                  id="recurringFrequency"
                  size="sm"
                  placeholder="Select Period"
                  options={convertArrayToSelectOptions(RECURRING_FREQUENCIES)}
                  required
                  label="This Event Repeats?"
                  labelClassName="tw-ml-4"
                  customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                />
              </div>
            </div>

            {values.recurringFrequency?.value ===
              RECURRING_FREQUENCY_VALUES.Monthly && (
              <div>
                <span className="tw-flex tw-gap-1 tw-ml-4">Recurring Type</span>
                <div className="tw-flex tw-text-base tw-mt-2">
                  <div
                    className={cn(
                      "tw-rounded-l-3xl tw-p-3 tw-px-5 tw-border tw-border-r-0 tw-cursor-pointer hover:tw-bg-secondary-800 hover:tw-text-white",
                      values.recurringType === RECURRING_TYPE_VALUES.Week &&
                        "tw-bg-secondary-800 tw-text-white"
                    )}
                    onClick={() =>
                      setFieldValue("recurringType", RECURRING_TYPE_VALUES.Week)
                    }
                  >
                    Week
                  </div>
                  <div
                    className={cn(
                      "tw-rounded-r-3xl tw-p-3 tw-px-5 tw-border tw-border-l-0  tw-cursor-pointer hover:tw-bg-secondary-800 hover:tw-text-white",
                      values.recurringType === RECURRING_TYPE_VALUES.Date &&
                        "tw-bg-secondary-800 tw-text-white"
                    )}
                    onClick={() =>
                      setFieldValue("recurringType", RECURRING_TYPE_VALUES.Date)
                    }
                  >
                    Date
                  </div>
                </div>
              </div>
            )}

            {values.recurringFrequency?.value ===
              RECURRING_FREQUENCY_VALUES.Monthly &&
              values.recurringType === RECURRING_TYPE_VALUES.Week && (
                <div>
                  <Field
                    component={ThemedSelect}
                    name="recurringWeek"
                    id="recurringWeek"
                    size="sm"
                    placeholder="Choose Option"
                    options={convertArrayToSelectOptions(MONTHLY_OCCURRENCE)}
                    required
                    label="Occurs Every"
                    labelClassName="tw-ml-4"
                    customControlStyle={SELECT_CUSTOM_CONTROL_STYLES}
                  />
                </div>
              )}

            {values.recurringFrequency &&
              values.recurringFrequency?.value !==
                RECURRING_FREQUENCY_VALUES.Daily &&
              values.recurringType !== RECURRING_TYPE_VALUES.Date && (
                <div>
                  <FieldArray
                    name="recurringRepeatDays"
                    render={(arrayHelpers) => (
                      <div>
                        <div className="tw-flex tw-gap-3 tw-flex-wrap tw-mt-2">
                          {RECURRING_REAPEAT_DAYS.map((day, Idx) => (
                            <div
                              key={Idx}
                              onClick={() => {
                                const findRecurringWeekDay =
                                  findRecurringWeekDayIndex(day.value);
                                if (findRecurringWeekDay > -1)
                                  return arrayHelpers.remove(
                                    findRecurringWeekDay
                                  );
                                return arrayHelpers.push(day.value);
                              }}
                              className={cn(
                                "tw-px-5 tw-py-2 tw-rounded-3xl tw-border hover:tw-scale-105 tw-cursor-pointer tw-text-base tw-transition-all",
                                findRecurringWeekDayIndex(day.value) > -1 &&
                                  "tw-bg-secondary-800 tw-text-white"
                              )}
                            >
                              {day.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}

            {values.recurringFrequency &&
              values.recurringFrequency?.value ===
                RECURRING_FREQUENCY_VALUES.Monthly &&
              values.recurringType === RECURRING_TYPE_VALUES.Date && (
                <div>
                  <FieldArray
                    name="recurringDays"
                    render={(arrayHelpers) => (
                      <div>
                        <div className="tw-flex tw-gap-3 tw-flex-wrap tw-mt-2">
                          {THIRTY_ONE_DAYS.map((date, Idx) => (
                            <div
                              key={Idx}
                              onClick={() => {
                                const findRecurringDate =
                                  findRecurringDateIndex(date.label);
                                if (findRecurringDate > -1)
                                  return arrayHelpers.remove(findRecurringDate);
                                return arrayHelpers.push(date.label);
                              }}
                              className={cn(
                                "tw-px-5 tw-py-2 tw-rounded-3xl tw-border hover:tw-scale-105 tw-cursor-pointer tw-text-base tw-transition-all",
                                findRecurringDateIndex(date.label) > -1 &&
                                  "tw-bg-secondary-800 tw-text-white"
                              )}
                            >
                              {date.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  />
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecurringDates;
