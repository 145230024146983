import { EventFallbackImage } from "assets/images";
import { hyphenate } from "utilities";

export const convertValueToDoubleDigits = (value) =>
  value < 10 ? `0${value}` : value;

export const isEventSavedByUser = (state, eventId) => {
  try {
    if (!state?.events?.userSavedEvents || !eventId) return false;
    return state.events.userSavedEvents.some((event) => event?.id === eventId);
  } catch (error) {
    console.error("Error checking saved event:", error);
    return false;
  }
};

export const dataURLtoFile = async (dataUrl) => {
  const base64Response = await fetch(dataUrl);
  const newBlob = await base64Response.blob();
  const mime = dataUrl.substring(
    dataUrl.indexOf(":") + 1,
    dataUrl.indexOf(";")
  );

  return new File([newBlob], mime.split("/")?.[1] || "png", { type: mime });
};

export const getEventThumbnailUrl = (event) => {
  return (
    event?.media?.bannerPhoto ||
    event?.media?.otherPhotos?.[0] ||
    EventFallbackImage
  );
};

export const getEventCheapestTicket = (event) => {
  return (
    event?.ticketTypes
      ?.map((ticketType) => ticketType)
      ?.sort((a, b) => Number(a.price) - Number(b.price))?.[0] || {}
  );
};

export const getEventPath = (event) => {
  return `/event/${event.isIG ? event.id : event.eventSerial}/${hyphenate(
    event.title
  )}`;
};
